.roi-cal {
  background: #f8f8f8;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  overflow: hidden;
}
.roi-cal__inner {
  background: #f8f8f8;
}
.roi-cal__overlay {
  background-color: rgba(0, 0, 0, 0.75);
}
.roi-cal__header {
  border-bottom: 1px solid #1a1a1a;
}
.roi-cal__title {
  color: #1a1a1a;
}
.roi-cal__week-day {
  color: #1a1a1a;
}
.roi-cal__day {
  background: transparent;
  border-color: transparent;
  border-radius: 0;
  color: #1a1a1a;
}
.roi-cal__day:hover:not(.roi-cal--unselectable),
.roi-cal__day:focus:not(.roi-cal--unselectable) {
  background: #1a1a1a;
  border-color: #1a1a1a;
  color: #f8f8f8;
}
.roi-cal__day.roi-cal--checkout:hover .roi-cal__day--nights::before,
.roi-cal__day.show-nights:hover .roi-cal__day--nights::before,
.roi-cal__day.roi-cal--checkout:hover .roi-cal__day--nights::after,
.roi-cal__day.show-nights:hover .roi-cal__day--nights::after {
  opacity: var(--roi-calendar-nights-opacity, 0);
  visibility: var(--roi-calendar-nights-visibility, hidden);
}
.roi-cal__day--nights::before {
  background: var(--roi-calendar-nights-bg, #f8f8f8);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  color: var(--roi-calendar-nights-color, #1a1a1a);
}
.roi-cal__day--nights::after {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 7px solid var(--roi-calendar-nights-bg, #f8f8f8);
}
.roi-cal__prev:after,
.roi-cal__next:after {
  border-bottom: 3px solid #1a1a1a;
  border-left: 3px solid #1a1a1a;
}
.roi-cal__close {
  background: #1a1a1a;
  border-color: #1a1a1a;
  color: #f8f8f8;
  border-radius: 7px;
  text-transform: uppercase;
}
.roi-cal__close:hover,
.roi-cal__close:focus {
  background: #1a1a1a;
  border-color: #1a1a1a;
  color: #f8f8f8;
}
.roi-cal--unselectable {
  background: transparent;
  border-color: transparent;
  color: #999999;
}
.roi-cal--selected {
  background: #1a1a1a;
  border-color: #1a1a1a;
  color: #f8f8f8;
}
.roi-cal--current {
  background: #e6e6e6;
  border-color: #e6e6e6;
  color: #f8f8f8;
}
.roi-cal--current:hover,
.roi-cal--current:focus {
  background: #1a1a1a;
  border-color: #1a1a1a;
  color: #f8f8f8;
}
.roi-cal--checkin {
  background: #1a1a1a;
  border-color: #1a1a1a;
  color: #f8f8f8;
}
.roi-cal--checkout {
  background: #1a1a1a;
  border-color: #1a1a1a;
  color: #f8f8f8;
}
.roi-cal--period {
  background: #cccccc;
  border-color: #cccccc;
  color: #1a1a1a;
}
.roi-cal--default {
  background: transparent;
  border-color: transparent;
  color: #1a1a1a;
}
.roi-cal--default:hover,
.roi-cal--default:focus {
  background: #1a1a1a;
  border-color: #1a1a1a;
  color: #f8f8f8;
}
/*# sourceMappingURL=search-engine-styles-custom.css.map */